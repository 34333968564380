import { formatEther, parseEther } from "@ethersproject/units";
import { BigNumber } from "@ethersproject/bignumber";
    

export function numtoBigString(number) {
    return parseEther(number.toString()).toString()
  }
  
 export function bigToNum(big) {
    //takes either a bignum or a bignum converted into a string (number with 18 zeroes)
    let formatted
    // console.log(formatEther(BigNumber.from(big)));
    (BigNumber.isBigNumber(big)) ? formatted = formatEther(big) : formatted = formatEther(BigNumber.from(big))
    return parseFloat(formatted)
  }