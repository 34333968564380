import React, { useState, useEffect } from "react";
import { Contract } from "@ethersproject/contracts";
import { getDefaultProvider } from "@ethersproject/providers";
// import { useQuery } from "@apollo/react-hooks";
import { formatEther, parseEther } from "@ethersproject/units";
import { Body, Button, Header, Image, Link, Input, Standout, Warning, Splits } from "./components";
import useWeb3Modal from "./hooks/useWeb3Modal";
import { getRateX, checkSwaps, getRatio } from "./readChainFunctions.js"
import { bigToNum, numtoBigString } from './helperFunctions'
import { addresses, abis, decimals } from "@project/contracts";
import { Line, Circle } from 'rc-progress';
import logo from './images/based-xxxswap-logo-transparent.png';


const xPairs = { Sushi: 'xSushi', Badger: 'bBadger', BASED: 'mbBASED' }
const ethToken = addresses.ethTokenPara; // ETH

//xtokens: sushi, based, eth/ibEth, aave/aAave, what others?

// import GET_TRANSFERS from "./graphql/subgraph";

// checkSwaps(divisor, amount, token, xToken, ratio, ethToken, buying)

function WalletButton({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {
  return (
    <Button
      onClick={() => {
        if (!provider) {
          loadWeb3Modal();
        } else {
          logoutOfWeb3Modal();
        }
      }}
    >
      {!provider ? "Connect Wallet" : "Disconnect Wallet"}
    </Button>
  );
}

function App() {
  // const { loading, error, data } = useQuery(GET_TRANSFERS);
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
  const [tokenRatio, setTokenRatio] = useState(0);
  const [tradeWithX, setTradeWithX] = useState()
  const [buyAmt, setBuyAmt] = useState('1')
  const [sellAmt, setSellAmt] = useState('200')
  const [tokenMode, setTokenMode] = useState({ token: 'Sushi', address: addresses.sushiToken, xAddress: addresses.xSushiToken })
  const [optimalTrade, setOptimalTrade] = useState('')
  const [tradeSplit, setTradeSplit] = useState(4)
  const [loadBar, setLoadBar] = useState(null)
  const [loadBar2, setLoadBar2] = useState(null)
  const [selected, setSelected] = useState(['selected', 'unselected', 'unselected'])

  async function basicCheckBuySell() {

    console.log(('basicCheckSwap running'));

    // const defaultProvider = getDefaultProvider();
    // let activeprovider
    // provider ? activeprovider = provider : activeprovider = defaultProvider

    // //For paraswap, amounts are strings of the numbers multiplied by its decimals: 1 = '1000000000000000000'

    // await getRatio(provider, tokenMode.token, setTokenRatio)

    let tokenReturnBuy, xTokenReturnBuy, tokenReturnSell, xTokenReturnSell

    setLoadBar2(10)

    try {
      const buyRoute = await getRateX(true, tokenRatio, tokenMode.address, decimals.sushi, buyAmt, false, ethToken)
      setLoadBar2(30)

      const buyXRoute = await getRateX(true, tokenRatio, tokenMode.xAddress, decimals.xSushi, buyAmt, true, ethToken)
      setLoadBar2(50)

      console.log(buyXRoute);

      tokenReturnBuy = buyRoute[0]
      xTokenReturnBuy = buyXRoute[0]

      console.log('buying ' + tokenMode.token + ' with ' + buyAmt + ' eth: ' + buyRoute[0]);
      console.log('buying ' + tokenMode.token + ' via ' + xPairs[tokenMode.token] + ' with ' + buyAmt + ' eth: ' + buyXRoute[0]);
    }
    catch (err) { console.log(err); }


    try {
      const sellRoute = await getRateX(false, tokenRatio, tokenMode.address, decimals.sushi, sellAmt, false, ethToken)
      setLoadBar2(70)

      const sellXRoute = await getRateX(false, tokenRatio, tokenMode.xAddress, decimals.xSushi, sellAmt, true, ethToken)
      setLoadBar2(90)

      tokenReturnSell = sellRoute[0]
      xTokenReturnSell = sellXRoute[0]

      console.log('selling ' + sellAmt + ' ' + tokenMode.token + ': ' + sellRoute[0]);
      console.log('selling ' + sellAmt + ' ' + tokenMode.token + 's worth of ' + xPairs[tokenMode.token] + ': ' + sellXRoute[0]);

    } catch (err) { console.log(err); }

    setLoadBar2(100)

    let tradeArray = [];

    (xTokenReturnBuy > tokenReturnBuy) ? tradeArray[0] = true : tradeArray[0] = false;
    (xTokenReturnSell > tokenReturnSell) ? tradeArray[1] = true : tradeArray[1] = false;

    console.log(xTokenReturnBuy, tokenReturnBuy, xTokenReturnSell, tokenReturnSell);

    console.log(tradeArray);

    setTradeWithX(tradeArray)
    setLoadBar2(null)


  }

  async function getOptimalSwap(buying, amount) {
    setLoadBar(10)
    const tradeResults = await checkSwaps(tradeSplit, amount, tokenMode.address, tokenMode.xAddress, tokenRatio, addresses.ethTokenPara, buying, setLoadBar)
    console.log(tradeResults);
    let i
    let buyorsell
    let tokensOut = []
    let percentages = []
    let details = []
    buying ? buyorsell = 'buying ' : buyorsell = 'selling '
    for (i = 0; i < tradeResults.length; i++) {
      let tokenPercent = (tradeSplit - i) / tradeSplit * 100
      let xTokenPercent = i / tradeSplit * 100
      let detail = 'Return for ' + buyorsell + tokenPercent + '% ' + tokenMode.token +
      ' and ' + xTokenPercent + '% ' + xPairs[tokenMode.token] + ' is ' + tradeResults[i][2].toFixed(4) + ' ' + tokenMode.token
      console.log(detail);
      tokensOut[i] = (tradeResults[i][2])
      percentages[i] = ([tokenPercent, xTokenPercent])
      details[i] = detail
    }
    let maxTokensOut = Math.max(...tokensOut)
    let index = tokensOut.indexOf(maxTokensOut)
    console.log(percentages[index]);
    console.log(maxTokensOut);
    let txToken
    buying ? txToken = 'Eth' : txToken = tokenMode.token
    let optimal = 'For ' + amount + ' ' + txToken + ' the optimal swap is ' + buyorsell +
      percentages[index][0] + '% in ' + tokenMode.token +
      ' and ' + percentages[index][1] + '% in ' + xPairs[tokenMode.token]
    console.log(optimal)
    console.log(details);
    setOptimalTrade([optimal,details])
    setLoadBar(null)
  }

  useEffect(() => {
    async function setStuff() {
      if (tokenMode.token == 'Sushi') setSelected(['selected', 'unselected', 'unselected'])
      else if (tokenMode.token == 'BASED') setSelected(['unselected', 'selected', 'unselected'])
      else if (tokenMode.token == 'Badger') setSelected(['unselected', 'unselected', 'selected'])
      setTradeWithX()
      await getRatio(provider, tokenMode.token, setTokenRatio)
    }

    setStuff()

  }, [tokenMode]);

  return (
    <div>
      <Header>
        {/* <WalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} /> */}
      </Header>
      <Body>
        <Image src={logo} />
        <Link onClick={() => setTokenMode({ token: 'Sushi', address: addresses.sushiToken, xAddress: addresses.xSushiToken })} className={selected[0]}>Sushi/xSushi</Link> {' | '}
        <Link onClick={() => setTokenMode({ token: 'BASED', address: addresses.basedToken, xAddress: addresses.moonBasedToken })} className={selected[1]}>Based/moonBased</Link> {' | '}
        <Link onClick={() => setTokenMode({ token: 'Badger', address: addresses.badgerToken, xAddress: addresses.bBadgerToken })} className={selected[2]}>Badger/bBadger</Link>
        <Standout>
          {xPairs[tokenMode.token]} / {tokenMode.token} Ratio: {(tokenRatio) ? tokenRatio : "Loading..."}
        </Standout>
        Buying {tokenMode.token} with this much Eth:<Input className="textinput" type="text" name="amount" size="0.75em" value={buyAmt} onChange={e => setBuyAmt(e.target.value)} />
        Selling this much {tokenMode.token}:<Input className="textinput" type="text" name="amount" size="0.75em" value={sellAmt} onChange={e => setSellAmt(e.target.value)} />


<>{tradeWithX && 
        <><p>{(tradeWithX[0]) 
        ? "It's better to buy " + xPairs[tokenMode.token] + ' than ' + tokenMode.token  + ' right now' 
        : "It's worse to buy " + xPairs[tokenMode.token] + ' than ' + tokenMode.token + ' right now' }
        </p>
        <p>
        {(tradeWithX[1]) 
        ? "It's better to sell " + xPairs[tokenMode.token] + ' than ' + tokenMode.token  + ' right now' 
        : "It's worse to sell " + xPairs[tokenMode.token] + ' than ' + tokenMode.token + ' right now'  }
        </p></>}</>
        {loadBar2 && <><p /> Loading... Checking Token vs xToken for best route... <p />   <Line percent={loadBar2} strokeWidth="3" strokeColor="#29ffb3" trailWidth="3" width="20%" /><p /></>}
        <Button onClick={() => tokenRatio && basicCheckBuySell()} disabled={!tokenRatio}>
          Check {xPairs[tokenMode.token]} vs {tokenMode.token} for buys and sells
        </Button>
        <Button onClick={() => tokenRatio && getOptimalSwap(true, buyAmt)} disabled={!tokenRatio}>
          Find optimal xToken split for buying {tokenMode.token}
        </Button>
        <Button onClick={() => tokenRatio && getOptimalSwap(false, sellAmt)} disabled={!tokenRatio}>
          Find optimal xToken split for selling {tokenMode.token}
        </Button>
        {!loadBar && optimalTrade[0] && (<Warning>Warning: This tool does not account for the cost of gas. If you include gas costs, the optimal split for your trade may be different</Warning>)}
        <Standout>{!loadBar && optimalTrade[0]}</Standout>
        {!loadBar && optimalTrade[1] && optimalTrade[1].map((text, index) => 
        <Splits key={index}>{text}</Splits>
        )}
        {loadBar && <><p /> Loading... Checking Paraswap for the optimal splits... <p />   <Line percent={loadBar} strokeWidth="3" strokeColor="#29ffb3" trailWidth="3" width="20%" /><p /></>}
      </Body>
    </div>
  );
}

export default App;
