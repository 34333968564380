import { ParaSwap } from 'paraswap';
import { bigToNum, numtoBigString } from './helperFunctions'
import { getDefaultProvider } from "@ethersproject/providers";
import { addresses, abis } from "@project/contracts";
import { Contract } from "@ethersproject/contracts";
import { formatEther, parseEther } from "@ethersproject/units";

const sideConstant = 'SELL'
const optionsConstant = {}
const ethDecimals = 18
const paraSwap = new ParaSwap();

export async function getRatio(provider, tokenCase, setTokenRatio) {
    setTokenRatio(0)
    // Should replace with the end-user wallet, e.g. Metamask
    const defaultProvider = getDefaultProvider();
    // Create an instance of an ethers.js Contract
    // Read more about ethers.js on https://docs.ethers.io/v5/api/contract/contract/
    let activeprovider
    provider ? activeprovider = provider : activeprovider = defaultProvider
    let ratio
    let formatRatio

    switch (tokenCase) {
        case 'Sushi':
            console.log(tokenCase);
            const xSushiContract = new Contract(addresses.xSushiToken, abis.xSushiToken, activeprovider)
            const sushiTokenContract = new Contract(addresses.sushiToken, abis.erc20, activeprovider)

            //find the ratio of sushi to xSushi

            const sushiStaked = await sushiTokenContract.balanceOf(addresses.xSushiToken)
            const totalxSushi = await xSushiContract.totalSupply()
            ratio = sushiStaked.mul(parseEther('1')).div(totalxSushi)
            formatRatio = formatEther(ratio)
            console.log(formatRatio);
            break

        case 'BASED':
            const moonBasedContract = new Contract(addresses.moonBasedToken, abis.moonBased, activeprovider)
            // const basedContract = new Contract(addresses.basedToken, abis.erc20, activeprovider)

            //find the ratio of sushi to xSushi

            const basedStaked = await moonBasedContract.balance()
            const totalMoonBased = await moonBasedContract.totalSupply()
            ratio = basedStaked.mul(parseEther('1')).div(totalMoonBased)
            formatRatio = formatEther(ratio)
            console.log(formatRatio);
            break

        case 'Badger':
            const bBadgerContract = new Contract(addresses.bBadgerToken, abis.bBadger, activeprovider)
            ratio = await bBadgerContract.getPricePerFullShare()
            formatRatio = formatEther(ratio)
            break
    }
    setTokenRatio(formatRatio)

}

export async function getRateX(buying, ratio, token, decimals, amount, xswap, ethToken) {

    if (buying && xswap) {
        let xAmount = await paraSwap.getRate(
            ethToken,
            token,
            numtoBigString(amount),
            sideConstant,
            optionsConstant,
            ethDecimals,
            decimals
        )

        let adjustedReceivedAmount = bigToNum(xAmount.destAmount) * ratio
        return [adjustedReceivedAmount, xAmount]

    }

    else if (buying && !xswap) {
        let receivedAmount = await paraSwap.getRate(
            ethToken,
            token,
            numtoBigString(amount),
            sideConstant,
            optionsConstant,
            ethDecimals,
            decimals
        )

        return [bigToNum(receivedAmount.destAmount), receivedAmount]

    }

    else if (!buying && xswap) {

        let xAmount = amount / ratio

        let receivedAmount = await paraSwap.getRate(
            token,
            ethToken,
            numtoBigString(xAmount),
            sideConstant,
            optionsConstant,
            decimals,
            ethDecimals
        )

        return [bigToNum(receivedAmount.destAmount), receivedAmount]

    }

    else if (!buying && !xswap) {

        let receivedAmount = await paraSwap.getRate(
            token,
            ethToken,
            numtoBigString(amount),
            sideConstant,
            optionsConstant,
            decimals,
            ethDecimals
        )

        return [bigToNum(receivedAmount.destAmount), receivedAmount]

    }

}

export async function checkSwaps(divisor, amount, token, xToken, ratio, ethToken, buying, setLoadBar) {

    let chunk = amount / divisor
    console.log('running checkswaps');
    let i
    let splits = []
    let basePercent = (1 / ((divisor + 1) * 2)) * 100
    for (i = 0; i <= divisor; i++) {
        console.log(basePercent);
        let percentage = ((i + 1) / (divisor + 1)) * 100
        // console.log('i is ', i);
//getRateX(buying, ratio, token, decimals, amount, xswap, ethToken)
        if (i === 0) {
            const receivedChunk = await getRateX(buying, ratio, token, 18, (chunk * divisor), false, ethToken)
            // console.log('i = ' + i + ' and 100% chunk sold :' + receivedChunk[0]);
            splits.push([receivedChunk[0], 0, receivedChunk[0]])

        }
        else if (i === divisor) {

            const convertedXChunk = await getRateX(buying, ratio, xToken, 18, (chunk * divisor), true, ethToken)
            // console.log('i = ' + i + ' and 100% xchunk sold :' + convertedXChunk[0]);
            splits.push([0, convertedXChunk[0], convertedXChunk[0]])
        }
        else {

            const receivedChunk = await getRateX(buying, ratio, token, 18, (chunk * (divisor - i)), false, ethToken)
            setLoadBar(percentage - basePercent)
            const convertedXChunk = await getRateX(buying, ratio, xToken, 18, (chunk * i), true, ethToken)

            // console.log('i = ' + i + ' and ' + ((divisor - i) / divisor) + ' chunk sold :' + receivedChunk[0] +
            //     ' and ' + (i / divisor) + ' xchunk sold :' + convertedXChunk[0]); // long console log
            const sumAmount = receivedChunk[0] + convertedXChunk[0]
            splits.push([receivedChunk[0], convertedXChunk[0], sumAmount])
        }
        setLoadBar(percentage)
    }
    // console.log(splits);
    return splits
}






// export async function noParaswapGetRateX(buying, ratio, amount, xswap, routerContract, route) {

//     // const buyResult = await routerContract.getAmountsOut(parseEther('1'), [addresses.wethToken, tokenAddress])
//     // const xBuyResult = await routerContract.getAmountsOut(parseEther('1'), [addresses.wethToken, xTokenAddress])

//     // const tokenReturnBuy = formatEther(buyResult[1])
//     // const xTokenReturnBuy = formatEther(xBuyResult[1].mul(ratio).div(parseEther('1')))

//     // console.log('buy 1 eth of sushi return is: ', tokenReturnBuy);
//     // console.log('buy 1 eth of xsushi return is: ', xTokenReturnBuy);

//     // const sellResult = await routerContract.getAmountsOut(parseEther('250'), [tokenAddress, addresses.wethToken])
//     // const xSellAmount = parseEther('250').mul(parseEther('1')).div(ratio)
//     // const xSellResult = await routerContract.getAmountsOut(xSellAmount, [xTokenAddress, addresses.wethToken])

//     // const tokenReturnSell = formatEther(sellResult[1])
//     // const xTokenReturnSell = formatEther(xSellResult[1])

//     // console.log('sell 250 sushi for eth return is: ', tokenReturnSell);
//     // console.log('sell 250 sushi worth of xSushi for eth return is: ', xTokenReturnSell);

//     // console.log(routerContract);
//     // console.log(amount);
//     // console.log(route);

//     if (buying && xswap) {

//         let xAmount = await routerContract.getAmountsOut(parseEther(amount), route)

//         console.log(xAmount);

//         let adjustedReceivedAmount = bigToNum(xAmount[route.length-1]) * ratio
//         return [adjustedReceivedAmount, xAmount]

//     }

//     else if (buying && !xswap) {
//         let receivedAmount = await routerContract.getAmountsOut(parseEther(amount), route)

//         return [bigToNum(receivedAmount[route.length-1]), receivedAmount]

//     }

//     else if (!buying && xswap) {

//         let xAmount = amount / ratio

//         let receivedAmount = await routerContract.getAmountsOut(parseEther(xAmount.toString()), route)

//         return [bigToNum(receivedAmount[route.length-1]), receivedAmount]

//     }

//     else if (!buying && !xswap) {

//         let receivedAmount = await routerContract.getAmountsOut(parseEther(amount), route)

//         return [bigToNum(receivedAmount[route.length-1]), receivedAmount]

//     }

// }