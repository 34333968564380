// This address points to a dummy ERC20 contract deployed on Ethereum Mainnet,
// Goerli, Kovan, Rinkeby and Ropsten. Replace it with your smart contracts.
const addresses = {
  ceaErc20: "0xc1C0472c0C80bCcDC7F5D01A376Bd97a734B8815",
  xSushiToken: "0x8798249c2E607446EfB7Ad49eC89dD1865Ff4272",
  sushiToken: "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2",
  moonBasedToken: "0x26cf82e4ae43d31ea51e72b663d26e26a75af729",
  basedToken: "0x68a118ef45063051eac49c7e647ce5ace48a68a5",
  bBadgerToken: "0x19d97d8fa813ee2f51ad4b4e04ea08baf4dffc28",
  badgerToken: "0x3472a5a71965499acd81997a54bba8d852c6e53d",
  sushiRouter: "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F",
  uniRouter: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  wethToken: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  ethTokenPara: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
};

export default addresses;
