// This address points to a dummy ERC20 contract deployed on Ethereum Mainnet,
// Goerli, Kovan, Rinkeby and Ropsten. Replace it with your smart contracts.
const decimals = {
    xSushi: 18,
    sushi: 18,
    moonBased: 18,
    based: 18,
    bBadger: 18,
    badger: 18,
    weth: 18
  };
  
  export default decimals;
  