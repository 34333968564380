import erc20Abi from "./abis/erc20.json";
import ownableAbi from "./abis/ownable.json";
import xSushiAbi from "./abis/sushibar.json";
import unirouterAbi from './abis/unirouter.json'
import moonBasedAbi from './abis/moonbased.json'
import bBadgerAbi from './abis/bbadger.json'


const abis = {
  erc20: erc20Abi,
  ownable: ownableAbi,
  xSushiToken: xSushiAbi,
  unirouter: unirouterAbi,
  moonBased: moonBasedAbi,
  bBadger: bBadgerAbi
};

export default abis;
