import styled from "styled-components";

export const Header = styled.header`
  background-color: #000000;
  min-height: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: white;
`;

export const Body = styled.div`
  align-items: center;
  background-color: #000000;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 0.5vmin);
  justify-content: center;
  min-height: calc(100vh - 0px);
  font-family:Tahoma ;
`;

export const Standout = styled.p`
font-weight: 700; // <Thing> tagged with an additional CSS class ".something"
color:#8c1eff	;
`;

export const Warning = styled.p`
font-weight: 500; // <Thing> tagged with an additional CSS class ".something"
color:#ff0000;
font-size: calc(6px + 0.5vmin);
`;

export const Splits = styled.div`
font-weight: 500; // <Thing> tagged with an additional CSS class ".something"
color:#ffffff;
font-size: calc(6px + 0.5vmin);
margin-bottom:1px;
`;

export const Image = styled.img`
  height: 15vmin;
  margin-bottom: 8px;
  pointer-events: none;
`;

export const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: 	#ff2975;
  display:contents;
  font-size: calc(10px + 1vmin);
  &:hover {
    color: #ff76a6; // <Thing> when hovered
    cursor: pointer;
  }
  &.selected {
    font-weight: 900; // <Thing> tagged with an additional CSS class ".something"
  }
  &.unselected {
    font-weight: normal; // <Thing> tagged with an additional CSS class ".something"
  }
`;

export const Button = styled.button`
  color:  white ;  
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: calc(10px + 0.5vmin);
  text-align: center;
  text-decoration: none;
  margin: 6px 20px;
  padding: 10px 10px;
  background-color: #8c1eff;
  :hover {
    background-color: #f222ff; // <Thing> when hovered
  }
  :disabled {
    opacity: 0.5;
    background-color: gray;
  }

  ${props => props.hidden && "hidden"} :focus {
    border: none;
    outline: none;
  }
`;

export const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.size || "1em",
}))`
  border: 2px solid hotpink;
  margin: 1em;
  padding: ${props => props.size};
`;

